import React, { Component, Fragment } from "react";
import { Users } from "react-feather";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { updateRefundRequestOrderStatusRedux } from "../../actions/index";
import {
  getSingleUser,
  getSingleRefundRequestOrder,
} from "../../firebase/firebase.utils";
import { sendNotifications } from "../../firebase/fcmRestApi";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { getMultipleOrders } from "../../firebase/firebase.utils";
import { CircleLoader } from "react-spinners";
export class Datatable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedValues: [],
      myData: this.props.myData,
      loader: false,
      paymentId: null,
    };
  }

  selectRow = (e, i) => {
    if (!e.target.checked) {
      this.setState({
        checkedValues: this.state.checkedValues.filter((item, j) => i !== item),
      });
    } else {
      this.state.checkedValues.push(i);
      this.setState({
        checkedValues: this.state.checkedValues,
      });
    }
  };

  handleRemoveRow = () => {
    const selectedValues = this.state.checkedValues;
    const updatedData = this.state.myData.filter(function (el) {
      return selectedValues.indexOf(el.id) < 0;
    });
    this.setState({
      myData: updatedData,
    });
    toast.success("Successfully Deleted !");
  };

  createNotification = (paymentRequestObj) => {
    let msg;
    if (paymentRequestObj.status === "approved") {
      msg = {
        title: `Payment ${paymentRequestObj.status}`,
        body: `Your payment for Payment Id:-${paymentRequestObj.paymentId} is ${paymentRequestObj.status}.`,
      };
      return msg;
    } else {
      msg = {
        title: `Payment ${paymentRequestObj.status}`,
        body: `Your payment for Payment Id:-${paymentRequestObj.paymentId} is rejected. Please Pay again or contact us for details.`,
      };
      return msg;
    }
  };

  Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  render() {
    const { pageSize, myClass, multiSelectOption, pagination } = this.props;
    console.log(this.props);
    const { myData } = this.props;
    console.log(myData);
    const newData = [];
    if (myData.length > 0) {
      myData.forEach((refundRequest) => {
        //  this is not affecting my output see line 104
        const userObj = this.props.allUser.find(
          (user) => user.uid === refundRequest.userId
        );
        newData.push({
          "Refund Id": refundRequest ? refundRequest.refundId : "",
          Method: refundRequest ? refundRequest.paymentMethod : "",
          Type:
            refundRequest && refundRequest.productRequest
              ? "Product Request"
              : "1688 Orders",
          "Order Id": refundRequest
            ? refundRequest.order.orderId
              ? refundRequest.order.orderId
              : refundRequest.order.bookingId
            : "",
          OrderTotal: refundRequest ? refundRequest.order.orderTotal : "",
        });
      });
    }
    const columns = [];
    for (var key in newData[0]) {
      columns.push({
        Header: <b>{this.Capitalize(key.toString())}</b>,
        accessor: key,
        Cell: null,
        style: {
          textAlign: "center",
        },
      });
    }

    if (multiSelectOption == true) {
      columns.push({
        Header: (
          <button
            className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
            onClick={(e) => {
              if (window.confirm("Are you sure you wish to delete this item?"))
                this.handleRemoveRow();
            }}
          >
            Delete
          </button>
        ),
        id: "delete",
        accessor: (str) => "delete",
        sortable: false,
        style: {
          textAlign: "center",
        },
        Cell: (row) => (
          <div>
            <span>
              <input
                type="checkbox"
                name={row.original.id}
                defaultChecked={this.state.checkedValues.includes(
                  row.original.id
                )}
                onChange={(e) => this.selectRow(e, row.original.id)}
              />
            </span>
          </div>
        ),
        accessor: key,
        style: {
          textAlign: "center",
        },
      });
    } else {
      columns.push(
        {
          Header: <b>status</b>,
          id: "delete",
          accessor: (str) => "delete",
          Cell: (row) => {
            if (myData.length > 0) {
              const refundRequest = myData.find(
                (refundRequest) =>
                  refundRequest.refundId === row.original["Refund Id"]
              );
              if (refundRequest.status === "pending") {
                return (
                  <div style={{ color: "orange" }}>
                    <i className="icofont-spinner-alt-3"></i>&nbsp;
                    {refundRequest.status}
                  </div>
                );
              }
              if (refundRequest.status === "approved") {
                return (
                  <div style={{ color: "green" }}>
                    <i className="icofont-like"></i>&nbsp;
                    {refundRequest.status}
                  </div>
                );
              }
              if (refundRequest.status === "reject") {
                return (
                  <div style={{ color: "red" }}>
                    <i className="icofont-close-squared"></i>&nbsp;
                    {refundRequest.status}
                  </div>
                );
              }
              return null;
            }
          },
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          Header: <b>Bkash/Bank Info</b>,
          id: "orderDetails",
          accessor: (str) => "orderDetails",
          Cell: (row) => {
            const refundObj = myData.find(
              (refund) => refund.refundId === row.original["Refund Id"]
            );
            console.log(refundObj);
            return (
              <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={
                  <Popover
                    id={`popover-positioned-bottom`}
                    style={{ minWidth: "12%" }}
                  >
                    <Popover.Title
                      style={{ backgroundColor: "#13c9ca", color: "white" }}
                      as="h3"
                    >
                      Bkash or Bank Information
                    </Popover.Title>
                    <Popover.Content className="popover-body-container">
                      <div
                        style={{
                          paddingBottom: "10px",
                        }}
                      >
                        {refundObj && refundObj.bankInfo && (
                          <div
                            style={{
                              borderBottom: "1px solid gainsboro",
                              paddingBottom: "5px",
                              paddingTop: 5,
                            }}
                          >
                            {refundObj.bankInfo}
                          </div>
                        )}
                      </div>
                    </Popover.Content>
                  </Popover>
                }
              >
                <button className="btn btn-secondary">info</button>
              </OverlayTrigger>
            );
          },
          style: {
            textAlign: "center",
          },
          sortable: false,
        },
        {
          Header: <b>Action</b>,
          id: "delete",
          accessor: (str) => "delete",
          Cell: (row) => {
            const refundRequest =
              myData.length > 0
                ? myData.find(
                    (refundRequest) =>
                      refundRequest.refundId === row.original["Refund Id"]
                  )
                : null;
            return (
              <div>
                {refundRequest && refundRequest.status === "pending" ? (
                  <>
                    <span style={{ cursor: "pointer", padding: "5px" }}>
                      <button
                        // className="btn"
                        style={{
                          backgroundColor: "green",
                          color: "white",
                          fontSize: 12,
                          maxWidth: "80px",
                          padding: "10px",
                          border: "none",
                          minWidth: 80,
                          minHeight: 30,
                          textAlign: "center",
                        }}
                        type="button"
                        onClick={async () => {
                          if (myData.length > 0) {
                            this.setState({
                              loader: true,
                              refundId: row.original["Refund Id"],
                            });

                            this.setState({
                              loader: false,
                              refundId: null,
                            });
                            this.props.startToggleModal(refundRequest);
                          }
                        }}
                      >
                        {this.state.refundId !== row.original["Refund Id"] && (
                          <i className="icofont-checked"> &nbsp; Approve</i>
                        )}
                        {this.state.refundId === row.original["Refund Id"] && (
                          <div style={{ marginTop: -5 }}>
                            <CircleLoader
                              loading={this.state.loader}
                              color="white"
                              size={15}
                            />
                          </div>
                        )}
                      </button>
                    </span>

                    <span style={{ cursor: "pointer" }}>
                      <button
                        // className="btn"
                        style={{
                          backgroundColor: "red",
                          color: "white",
                          fontSize: "70%",
                          maxWidth: "80px",
                          padding: "10px",
                          border: "none",
                        }}
                        type="button"
                        onClick={async () => {
                          if (myData.length > 0) {
                            const refundRequest = myData.find(
                              (refundRequest) =>
                                refundRequest.refundId ==
                                row.original["Refund Id"]
                            );
                            await this.props.updateRefundRequestOrderStatusRedux(
                              {
                                ...refundRequest,
                                status: "reject",
                              }
                            );
                            const newRequest =
                              await getSingleRefundRequestOrder(
                                refundRequest.refundId
                              );

                            const msg = this.createNotification(newRequest);
                            console.log(msg);
                            const user = await getSingleUser(
                              refundRequest.order.userId
                            );
                            console.log(user);
                            const message = {
                              title: msg.title,
                              body: msg.body,
                            };
                            if (
                              user.deviceToken &&
                              user.deviceToken.length > 0
                            ) {
                              user.deviceToken.map((token) => {
                                console.log(token);
                                sendNotifications(token, message);
                              });
                            }
                          }
                        }}
                      >
                        {" "}
                        <i className="icofont-close-squared"></i>&nbsp; Reject
                      </button>
                    </span>
                  </>
                ) : null}
              </div>
            );
          },
          style: {
            textAlign: "center",
          },
          sortable: false,
        }
      );
    }

    return (
      <Fragment>
        <ReactTable
          data={newData}
          columns={columns}
          defaultPageSize={pageSize}
          className={myClass}
          showPagination={pagination}
        />
        <ToastContainer />
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    allUser: state.users.users,
  };
};
export default connect(mapStateToProps, {
  updateRefundRequestOrderStatusRedux,
})(Datatable);
